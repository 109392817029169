/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * --------------------------------------------------------------------------------
 * This file contains the sae landing page component
 * --------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * Imports - External
 * ---------------------------------------------------------------------------------
 */

/**
 * Required to make use of JSX functionality
 */
import * as React from 'react';

import { Button, makeStyles, Theme, Typography, Grid } from '@material-ui/core';

import { useParams, useHistory } from 'react-router-dom';

import {
    InstitutionsContext,
    InstitutionBreadcrumbs,
    IFormLabel,

    ALL_INSTITUTIONS_CODE,
    TrialContextSelector,
    PatientsByCodesResolver,
    PatientsContext
} from '@ngt/opms';

import {
    ScreeningLogResolver, useScreeningLogs
} from '@ngt/opms-screeninglog';

/*
 * ---------------------------------------------------------------------------------
 * Imports - Internal
 * ---------------------------------------------------------------------------------
 */
import useScreenedParticipantsColumns from '../../hooks/screeninglogs/useScreenedParticipantsColumns';
import useScreenFailsColumns from '../../hooks/screeninglogs/useScreenFailsColumns';
import ScreeningLogFieldGroup from './ScreeningLogFieldGroup';
import * as Dtos from '../../api/dtos';
import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';
import { ScreeningLogDialog, ScreeningLogDialogMode } from '@ngt/opms-screeninglog';
import { IRequestState, RequestState } from '@ngt/request-utilities';
import { DateTime } from 'luxon';
import ScreeningLogsCollapsibleTable from './ScreeningLogCollapsibleTable';

/*
 * ---------------------------------------------------------------------------------
 * Interfaces
 * ---------------------------------------------------------------------------------
 */

interface IScreeningLogPageProps {
}

interface IScreeningLogsProps {
    institution?: Dtos.Institution;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles<Theme>(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    createGrid: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-Start',
        alignItems: 'flex-end'
    },
    createGridBtn: {
        '&:first-child': {
            marginRight: theme.spacing(1)
        },
        width: '100%'
    },
    ml1: {
        marginLeft: theme.spacing(1),
    },
    mt1: {
        marginTop: theme.spacing(1),
    },
    mt3: {
        marginTop: theme.spacing(3)
    },
    mr1: {
        marginRight: theme.spacing(1)
    },
}));

/*
 * ---------------------------------------------------------------------------------
 * Components
 * ---------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.ScreeningLogView,
    Permission.ScreeningLogUpdate,
];

const patientStates = [Dtos.PatientStateType.Randomised,
    Dtos.PatientStateType.AwaitingRandomisation,
    Dtos.PatientStateType.ThreeMonthFollowUp,
    Dtos.PatientStateType.SixMonthFollowUp,
    Dtos.PatientStateType.TwelveMonthFollowUp,
    Dtos.PatientStateType.TwentyFourMonthFollowUp];

const ScreeningLogTables: React.FunctionComponent<{
    setScreeningLogId: any,
    setDialogMode: any,
    screeningLogs: Dtos.IScreeningLog[] | null,
    screeningLogsLoadState: IRequestState<Dtos.ResponseStatus>,
    canDeleteScreeningLog?: boolean
}> = ({
    setScreeningLogId,
    setDialogMode,
    screeningLogs,
    screeningLogsLoadState,
    canDeleteScreeningLog
}) => {
    const { institutions } = React.useContext(InstitutionsContext);

    const { institutionCode } = useParams<Record<string, string>>();

    const showInstitutions = React.useMemo(() => {
        return !institutionCode
    }, [institutionCode]);

    const institution = React.useMemo(() => {
        return institutions?.find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    const pendingScreeningColumns = useScreenedParticipantsColumns(institutions, showInstitutions, false);

    const successfulScreeningAwaitingRandoColumns = useScreenedParticipantsColumns(institutions, showInstitutions, false);

    const successfulScreeningColumns = useScreenedParticipantsColumns(institutions, showInstitutions, true);

    const screenFailsColumns = useScreenFailsColumns(institutions, showInstitutions);

    const handleUpdate = (event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IScreeningLog | undefined) => {
        if (!rowData?.id) {
            throw new Error('Screening log ID was not provided.');
        }
        setScreeningLogId(rowData?.id);
        setDialogMode(ScreeningLogDialogMode.update);
    };

    const handleDelete = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, id?: number) => {
        if (!id) {
            throw new Error('Screening log ID was not provided.');
        }

        event.stopPropagation();

        setScreeningLogId(id);
        setDialogMode(ScreeningLogDialogMode.delete);
    };

    const pendingScreeningLogsToUse: Dtos.ScreeningLog[] = React.useMemo(() => {
        return screeningLogs?.filter((s: Dtos.ScreeningLog) => s.status === Dtos.ScreeningStatus.Pending) as Dtos.ScreeningLog[] ?? [];
    }, [screeningLogs]);

    const econsentScreeningLogsToUse: Dtos.ScreeningLog[] = React.useMemo(() => {

        return screeningLogs?.filter((s: Dtos.ScreeningLog) => {

            if ((s as Dtos.ScreeningLog).econsentInvitationId && !s.patientId) {
                return true;
            }

            return false;
        }) as Dtos.ScreeningLog[] ?? [];

    }, [screeningLogs]);

    const { patients } = React.useContext(PatientsContext);

    const awaitingRandomisationScreeningLogsToUse: Dtos.ScreeningLog[] = React.useMemo(() => {

        return screeningLogs?.filter((s: Dtos.ScreeningLog) => {

            if (s.patientId) {
                const patient = patients?.find(p => p.id === s.patientId);

                return patient?.patientStateId === Dtos.PatientStateType.AwaitingRandomisation;
            }

            return false;
        }) as Dtos.ScreeningLog[] ?? [];

    }, [screeningLogs, patients]);

    const randomisedScreeningLogsToUse: Dtos.ScreeningLog[] = React.useMemo(() => {

        return screeningLogs?.filter((s: Dtos.ScreeningLog) => {

            if (s.patientId) {
                const patient = patients?.find(p => p.id === s.patientId);

                const stateId = patient?.patientStateId ?? 0

                return stateId >= Dtos.PatientStateType.Randomised && stateId < Dtos.PatientStateType.Ineligible;
            }

            return false;
        }) as Dtos.ScreeningLog[] ?? [];

    }, [screeningLogs, patients]);

    const failedScreeningLogsToUse: Dtos.ScreeningLog[] = React.useMemo(() => {
        return screeningLogs?.filter((s: Dtos.ScreeningLog) => s.status === Dtos.ScreeningStatus.Failed) as Dtos.ScreeningLog[] ?? [];
    }, [screeningLogs]);

    return (
        <>
            <ScreeningLogsCollapsibleTable
                columns={pendingScreeningColumns}
                title={"Participants in Screening"}
                pluralizeTitle={false}
                data={pendingScreeningLogsToUse}
                loading={screeningLogsLoadState.state === RequestState.None || screeningLogsLoadState.state === RequestState.Pending}
                onRowClick={handleUpdate as any}
                onRemove={handleDelete as any}
                allowDelete={true}
                canDeleteScreeningLog={canDeleteScreeningLog}
            />

            <ScreeningLogsCollapsibleTable
                columns={pendingScreeningColumns}
                title={"Participants in Participant Information Portal"}
                pluralizeTitle={false}
                data={econsentScreeningLogsToUse}
                loading={screeningLogsLoadState.state === RequestState.None || screeningLogsLoadState.state === RequestState.Pending}
                onRowClick={handleUpdate as any}
                onRemove={handleDelete as any}
                allowDelete={false}
                canDeleteScreeningLog={canDeleteScreeningLog}
            />

            <ScreeningLogsCollapsibleTable
                columns={successfulScreeningAwaitingRandoColumns}
                title={"Participants Awaiting Randomisation"}
                pluralizeTitle={false}
                data={awaitingRandomisationScreeningLogsToUse}
                loading={screeningLogsLoadState.state === RequestState.None || screeningLogsLoadState.state === RequestState.Pending}
                onRowClick={handleUpdate as any}
                onRemove={handleDelete as any}
                allowDelete={false}
                canDeleteScreeningLog={canDeleteScreeningLog}
            />

            <ScreeningLogsCollapsibleTable
                columns={successfulScreeningColumns}
                title={"Participants Randomised"}
                pluralizeTitle={false}
                data={randomisedScreeningLogsToUse}
                loading={screeningLogsLoadState.state === RequestState.None || screeningLogsLoadState.state === RequestState.Pending}
                onRowClick={handleUpdate as any}
                onRemove={handleDelete as any}
                allowDelete={false}
                canDeleteScreeningLog={canDeleteScreeningLog}
            />

            <ScreeningLogsCollapsibleTable
                columns={screenFailsColumns}
                title={"Failed Screenings/Registration"}
                pluralizeTitle={false}
                data={failedScreeningLogsToUse}
                loading={screeningLogsLoadState.state === RequestState.None || screeningLogsLoadState.state === RequestState.Pending}
                onRowClick={handleUpdate as any}
                onRemove={handleDelete as any}
                canDeleteScreeningLog={canDeleteScreeningLog}
            />
        </>
    );
}

const ScreeningLogs: React.FunctionComponent<IScreeningLogsProps> = ({
    institution
}) =>
{
    const [screeningLogs, screeningLogsLoadState, { load: loadScreeningLogs }] = useScreeningLogs(institution?.id, true);
    
    const classes = useStyles();

    const history = useHistory();

    const [[canViewScreeningLog, canUpdateScreeningLog]] = usePermissionsByIds(permissions, null, null, institution?.id, null, institution ? true : false);

    const onSelect = React.useCallback((newMasterGroupCode?: string | null, newCollaboratingGroupCode?: string | null, newCountryCode?: string | null, newInstitutionCode?: string | null) => {
        if (newInstitutionCode) {
            history?.push(`/screening/${newInstitutionCode}`);
        }
        else {
            history?.push(`/screening`);
        }

        return;
    }, [history]);

    const [dialogMode, setDialogMode] = React.useState(0);

    const [screeningLogId, setScreeningLogId] = React.useState<number | undefined>(undefined);

    const handleAdd = () => {
        setDialogMode(ScreeningLogDialogMode.create);
    };

    const handleCancel = () => {
        setScreeningLogId(undefined);
        setDialogMode(ScreeningLogDialogMode.cancel)
    }; 

    const labels: IFormLabel[] = [
        {
            name: 'screeningDate',
            label: 'Screening date',
            detailedLabel: ''
        },
        {
            name: 'initials',
            label: 'Initials',
            detailedLabel: ''
        },
        {
            name: 'outcome',
            label: 'Outcome',
            detailedLabel: ''
        },
        {
            name: 'reason',
            label: 'Reason for screen fail',
            detailedLabel: ''
        },
        {
            name: 'reasonSpecify',
            label: 'Specify',
            detailedLabel: ''
        },
        {
            name: 'reasonNotApproached',
            label: 'Reason eligible patient is not approached',
            detailedLabel: ''
        },
        {
            name: 'moreThan10NodesInvolved',
            label: '1 &nbsp; ≥10 nodes/internal mammary node involved',
            detailedLabel: undefined as any
        },
        {
            name: 'erNegativeOrHer2Positive',
            label: '2 &nbsp; ER Negative or HER2 positive/amplified',
            detailedLabel: undefined as any
        },
        {
            name: 'metastaticDisease',
            label: '3 &nbsp; Metastatic disease',
            detailedLabel: undefined as any
        },
        {
            name: 'previousMalignancy',
            label: '4 &nbsp; Previous malignancy',
            detailedLabel: undefined as any
        },
        {
            name: 'hrtAtTimeOfSurgery',
            label: '5 &nbsp; HRT at time of surgery',
            detailedLabel: undefined as any
        },
        {
            name: 'presurgicalChemo',
            label: '6 &nbsp; Pre-surgical chemotherapy/endocrine therapy/radiotherapy',
            detailedLabel: undefined as any
        },
        {
            name: 'begunAdjuvantTreatment',
            label: '7 &nbsp; Begun adjuvant treatment before trial entry',
            detailedLabel: undefined as any
        },
        {
            name: 'moreThan8WeerksAfterCompletion',
            label: '8 &nbsp; >8wks after completion of breast cancer surgery',
            detailedLabel: undefined as any
        },
        {
            name: 'plannedFurtherSurgery',
            label: '9 &nbsp; Planned further surgery for breast cancer (see protocol for detail)',
            detailedLabel: undefined as any
        },
        {
            name: 'other',
            label: '10 &nbsp; Other, please specify:',
            detailedLabel: undefined as any
        },
        {
            name: 'patientUsingEconsent',
            label: 'Would you like to invite your participant to view the Participant Information Webpage?',
            detailedLabel: ''
        },
        {
            name: 'proficientInEnglish',
            label: 'Is the patient proficient in English?',
            detailedLabel: ''
        },
        {
            name: 'patientHasEmail',
            label: 'Does the patient have an email address?',
            detailedLabel: ''
        },
        {
            name: 'patientEmail',
            label: 'Email address',
            detailedLabel: ''
        },
        {
            name: 'econsentInvestigator',
            label: 'Investigator',
            detailedLabel: ''
        }
    ];

    const downloadButtons =
        <>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                component="a"
                className={classes.mr1}
                href={`/print/screeninglogs/${(institution?.code ?? 'all-institutions')}/pdf`}
            >
                PDF
            </Button>
            <Button
                variant="contained"
                color="primary"
                size="medium"
                component="a"
                href={`/print/screeninglogs/${(institution?.code ?? 'all-institutions')}/csv`}
            >
                CSV
            </Button>
        </>;

    return (
        <>
            <InstitutionBreadcrumbs />
            <div
                className={classes.container}
            >
                <Typography
                    className={classes.mt1}
                >
                    Please include information for <strong><u>ALL</u></strong> patients identified by MDT as <strong>potentially eligible as thought to have ER positive and HER2 negative disease and would ordinarily be offered adjuvant chemotherapy</strong>.
                    <br /><br />
                    This includes those who did not proceed to randomisation or declined trial participation.  Click ‘Add Screening Log’ to add a screened patient to the log and enter the available information. Screening logs with incomplete responses can be saved and completed later once the missing information becomes available. Once all the questions are answered correctly, complete the screening to move the patient to the next stage. Depending on the answers provided on the form, the patient may go further to 'eConsent' or 'Randomisation', or fall under 'Failed Screenings'. To view or update a patient's screening log, simply click the record in the table.
                    <br /><br />
                    NOTE: All patients must be entered in the screening log for their record to be available in the Randomisation tab within the BCT OPMS. This is critical to ensure that details of pathology shipment for Prosigna testing can be captured and the required Pathology Requisition Form generated.  
                </Typography>

                <Grid item xs={12} className={classes.mt3}>
                    <TrialContextSelector
                        onChange={onSelect}
                        allowAllMasterGroups={false}
                        allowAllCollaboratingGroups={false}
                        allowAllCountries={false}
                        allowAllInstitutions={true}
                        hideMasterGroups={true}
                        hideCollaboratingGroups={true}
                        hideCountries={true}
                        masterGroupCode={null}
                        collaboratingGroupCode={null}
                        countryCode={null}
                        institutionCode={institution?.code ?? ALL_INSTITUTIONS_CODE}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            className={classes.createGrid}
                        >
                            {
                                canUpdateScreeningLog && <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    onClick={handleAdd}
                                    className={classes.createGridBtn}
                                    disabled={!institution?.code}
                                >
                                    Add Screening Log
                                </Button>
                            }
                            {
                                canViewScreeningLog &&
                                downloadButtons
                            }
                        </Grid>
                    </TrialContextSelector>
                </Grid>

                <ScreeningLogTables
                    setScreeningLogId={setScreeningLogId}
                    setDialogMode={setDialogMode}
                    screeningLogs={screeningLogs}
                    screeningLogsLoadState={screeningLogsLoadState}
                    canDeleteScreeningLog={canUpdateScreeningLog}
                />

                {
                    (!!institution?.id || !!screeningLogId) && (
                        <ScreeningLogResolver id={screeningLogId} institutionId={institution?.id}>
                            <ScreeningLogDialog
                                mode={dialogMode}
                                formCancel={handleCancel}
                                formFieldGroup={<ScreeningLogFieldGroup />}
                                formLabels={labels}
                                formCreateSuccessfulSaveMessage={(formAndSubmit) => {
                                    const submitType = formAndSubmit.submitType;
                                    const screeningLog = formAndSubmit.form as Dtos.ScreeningLog;

                                    if (submitType === "save") {
                                        return `Participant ${screeningLog.initials} (DOB: ${screeningLog.dateOfBirth && DateTime.fromISO(screeningLog.dateOfBirth).toFormat('dd/MM/yyyy')}) was successfully saved to "Participants in Screening".`
                                    } else if (submitType === "submit") {

                                        if (screeningLog.outcome === Dtos.ScreeningOutcome.ProceedToRegistration) {
                                            if (screeningLog.patientUsingEconsent !== Dtos.LookupYesNoType.Yes as number) {
                                                return `Participant ${screeningLog.patientStudyNumber} (Initials: ${screeningLog.initials}, DOB: ${screeningLog.dateOfBirth && DateTime.fromISO(screeningLog.dateOfBirth).toFormat('dd/MM/yyyy')}) was successfully added to Randomisation. Click on Randomisation tab to complete this participant’s randomisation.`
                                            } else {
                                                return `Participant ${screeningLog.initials} (DOB: ${screeningLog.dateOfBirth && DateTime.fromISO(screeningLog.dateOfBirth).toFormat('dd/MM/yyyy')}) was successfully saved to "Participants in Participant Information Portal". Click on Participant Information Portal tab to complete this participant’s consent.`
                                            }
                                        } else if (screeningLog.outcome === Dtos.ScreeningOutcome.ScreenFail) {
                                            return `Participant ${screeningLog.initials} (DOB: ${screeningLog.dateOfBirth && DateTime.fromISO(screeningLog.dateOfBirth).toFormat('dd/MM/yyyy')}) was successfully saved as "Screen Fail".`
                                        }
                                    }

                                    return 'Screening log was successfully saved.'
                                }}
                            />
                        </ScreeningLogResolver>
                    )
                }
            </div>
        </>
    )
}

const ScreeningLogPage: React.FunctionComponent<IScreeningLogPageProps> = () =>
{
    const { institutionCode } = useParams<Record<string, string>>();

    const contextInstitutions = React.useContext(InstitutionsContext);

    const institutions = contextInstitutions?.institutions;

    const institution = React.useMemo(() => {
        return institutions?.find(i => i.code === institutionCode);
    }, [institutions, institutionCode]);

    return (
        <>
            <PatientsByCodesResolver
                masterGroupCode={null}
                collaboratingGroupCode={null}
                countryCode={null}
                institutionCode={institution?.code}
                patientStateIds={patientStates}
            >
                <ScreeningLogs
                    institution={institution}
                />
            </PatientsByCodesResolver>
        </>
    )
}

/*
 * ---------------------------------------------------------------------------------
 * Default Export
 * ---------------------------------------------------------------------------------
 */
export default ScreeningLogPage;