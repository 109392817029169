
/*
 * ---------------------------------------------------------------------------------
 * Copyright:
 *      NewtonGreen Technologies Pty. Ltd.
 *      Level 4, 175 Scott St.
 *      Newcastle, NSW, 2300
 *      Australia
 *
 *      E-mail: support@newtongreen.com
 *      Tel: (02) 4925 5288
 *      Fax: (02) 4925 3068
 *
 *      All Rights Reserved.
 * ---------------------------------------------------------------------------------
 */

/*
 * ---------------------------------------------------------------------------------
 * This file contains the component that provides context for the online patient
 * management system.
 * ---------------------------------------------------------------------------------
 */

/*
 * ----------------------------------------------------------------------------------
 * Imports - External
 * ----------------------------------------------------------------------------------
 */

/**
 * Required to use React components.
 */
import * as React from 'react';

/*
 * Used to get access to router context.
 */
import { useHistory, useParams } from 'react-router-dom';

/*
 * Used to style components
 */
import { makeStyles, Grid, Button, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions, TextField } from '@material-ui/core';

/**
 * Used for the basic page layout.
 */
import {
    TrialContextSelector,
    ALL_MASTER_GROUPS_CODE,
    ALL_COLLABORATING_GROUPS_CODE,
    ALL_COUNTRIES_CODE,
    ALL_INSTITUTIONS_CODE,

    InstitutionsContext,
    InstitutionBreadcrumbs,
    OnlinePatientManagementContext,
    PatientsContext,

    usePatientsByCodes,
    useSnackbar,
    CollapsibleTable
} from '@ngt/opms';

/*
 * ----------------------------------------------------------------------------------
 * Imports - Internal
 * ----------------------------------------------------------------------------------
 */

/*
 * Used to type patient state.
 */
import * as Dtos from '../api/dtos';
import Alert from '@material-ui/lab/Alert';
import useRandomisedColumns from '../hooks/useRandomisedColumns';
import useAwaitingRandomisationPatientColumns from '../hooks/useAwaitingRandomisationPatientColumns';
import { Permission, usePermissionsByIds } from '@ngt/opms-bctapi';
import PatientsByCodesAndFilterResolver from '../components/resolver/PatientsByCodesAndFilterResolver';
import ProCompletionGuidelines from './ProCompletionGuidelines';
import useRandomisedIneligibleColumns from '../hooks/useRandomisedIneligibleColumns';
import ConsentReviewDialogWrapper from '../components/dialogs/ConsentReviewDialog';
import { AlertTitle } from '@material-ui/lab';
import { RequestState } from '@ngt/request-utilities';
import { Column } from 'material-table';

/*
 * ----------------------------------------------------------------------------------
 * Interface
 * ----------------------------------------------------------------------------------
 */

interface IRegistrationParams {
    masterGroupCode?: string
    collaboratingGroupCode?: string
    countryCode?: string
    institutionCode?: string
}

interface IRegistrationProps {
    showAdminPage?: boolean;
}

interface IPatientTableProps {
    title: string;
    entityName: string;
    columns: Array<Column<Dtos.Patient>>;
    onRowClick?: (event: any, rowData: any) => void,
    centralLab?: boolean;
}

/*
 * ---------------------------------------------------------------------------------
 * Styles
 * ---------------------------------------------------------------------------------
 */

const useStyles = makeStyles(theme => ({
    container: {
        padding: theme.spacing(3)
    },
    addPatientColumn: {
        display: 'flex',
        alignItems: 'flex-end'
    },
    search: {
        display: 'flex',
        alignItems: 'flex-end',
        paddingRight: '12px',
        paddingTop: '12px',
        paddingBottom: '12px',
        gap: 24
    },
    addPatient: {
        width: '100%'
    },
    proFormsCompletionContainer: {
        paddingTop: theme.spacing(1),
    },
    proFormsCompletionButtonContainer: {
        width: '50%',
        paddingLeft: theme.spacing(1.5)
    },
    recruitmentDisabled: {
        marginTop: theme.spacing(2)
    },
    consent: {
        borderBottom: () => `1px solid ${theme.palette.grey[300]}`,
        borderTop: () => `1px solid ${theme.palette.grey[300]}`,
        marginTop: () =>  -1,
    },
    consentGrid: {
        padding: theme.spacing(3)
    }
}));

/*
 * ----------------------------------------------------------------------------------
 * Components
 * ----------------------------------------------------------------------------------
 */

const permissions: Permission[] = [
    Permission.OpmsConsentAdminister,
    Permission.OpmsPatientUpdate,
    Permission.PathologyTestAdminister
];

const awaitingRandomisationStates = [Dtos.PatientStateType.AwaitingRandomisation];
const randomisedStates = [Dtos.PatientStateType.Randomised, Dtos.PatientStateType.ThreeMonthFollowUp, Dtos.PatientStateType.SixMonthFollowUp, Dtos.PatientStateType.TwelveMonthFollowUp, Dtos.PatientStateType.TwentyFourMonthFollowUp];
const ineligibleStates = [Dtos.PatientStateType.Ineligible];

const Search: React.FunctionComponent<{
}> = () => {

    const history = useHistory();

    const [patients] = usePatientsByCodes(null, null, null, null, null, true);

    const [searchValue, setSearchValue] = React.useState(null);

    const { enqueueSnackbar } = useSnackbar();

    const saveText = React.useCallback((e) => {
        setSearchValue(e.target.value);
    }, [searchValue, setSearchValue]);

    const search = React.useCallback(() => {
        if (patients?.some(p => p.studyNumber === searchValue)) {
            history.push(`/registration/all-institutions/` + searchValue);
        }
        else {
            enqueueSnackbar(
                <>
                    <AlertTitle>
                        Patient Not Found
                    </AlertTitle>
                    {searchValue ? <>Patient '{searchValue}' not found. Please ensure you have correctly input the patients study number.</> : <>The search field is empty.</>}
                </>,
                { variant: 'error' }
            );
        }
    }, [searchValue, enqueueSnackbar, history, patients]);

    return (
        <>

            <TextField
                variant="standard"
                label="Patient Study Number"
                id='patientNumberSearch'
                onChange={saveText}
                style={{
                    flex: '1 1 auto',
                    width: '60%'
                }}
            />
            <Button
                variant='contained'
                onClick={search}

                style={{
                    flex: '1 1 auto',
                    width: '40%'
                }}
            >
                Search
            </Button>
        </>
    )
}


const Registration: React.FunctionComponent<IRegistrationProps> = ({
    showAdminPage
}) => {
    const classes = useStyles();

    const history = useHistory();

    const { masterGroupCode, collaboratingGroupCode, countryCode, institutionCode } = useParams<IRegistrationParams>();

    const onSelect = React.useCallback((newMasterGroupCode?: string | null, newCollaboratingGroupCode?: string | null, newCountryCode?: string | null, newInstitutionCode?: string | null) => {
        if (!showAdminPage) {
            if (newInstitutionCode) {
                history.push(`/registration/${newInstitutionCode}`);
            }
            else {
                history.push(`/registration`);
            }

            return;
        }

        if (newInstitutionCode) {
            history.push(`/registration/admin/${(newMasterGroupCode ?? ALL_MASTER_GROUPS_CODE)}/${(newCollaboratingGroupCode ?? ALL_COLLABORATING_GROUPS_CODE)}/${(newCountryCode ?? ALL_COUNTRIES_CODE)}/${newInstitutionCode}`);
        }
        else if (newCountryCode) {
            history.push(`/registration/admin/${(newMasterGroupCode ?? ALL_MASTER_GROUPS_CODE)}/${(newCollaboratingGroupCode ?? ALL_COLLABORATING_GROUPS_CODE)}/${newCountryCode}`);

        }
        else if (newCollaboratingGroupCode) {
            history.push(`/registration/admin/${(newMasterGroupCode ?? ALL_MASTER_GROUPS_CODE)}/${newCollaboratingGroupCode}`);

        }
        else if (newMasterGroupCode) {
            history.push(`/registration/admin/${newMasterGroupCode}`);
        }
        else {
            history.push(`/registration/admin`);
        }
    }, [history, showAdminPage]);

    const parsedMasterGroupCode = React.useMemo(() => {
        return masterGroupCode !== ALL_MASTER_GROUPS_CODE ? masterGroupCode : null;
    }, [masterGroupCode]);

    const parsedCollaboratingGroupCode = React.useMemo(() => {
        return collaboratingGroupCode !== ALL_COLLABORATING_GROUPS_CODE ? collaboratingGroupCode : null;
    }, [collaboratingGroupCode]);

    const parsedCountryCode = React.useMemo(() => {
        return countryCode !== ALL_COUNTRIES_CODE ? countryCode : null;
    }, [countryCode]);

    const parsedInstitutionCode = React.useMemo(() => {
        return institutionCode !== ALL_INSTITUTIONS_CODE ? institutionCode : null;
    }, [institutionCode]);

    const { institutions } = React.useContext(InstitutionsContext);

    const institution = React.useMemo(() => {
        return institutions?.find(institution => institution.code === parsedInstitutionCode);
    }, [parsedInstitutionCode, institutions])

    const tableOptions = {
        pageSize: 5,
        pageSizeOptions: [5, 10, 20]
    };
    
    const MemoizedCollapsibleTable = React.memo(CollapsibleTable);
    
    const PatientTable: React.FunctionComponent<IPatientTableProps> = ({
        title,
        entityName,
        columns,
        onRowClick,
        centralLab
    }) => {
        const [tableCount, setTableCount] = React.useState(1);
    
        const { patients: contextPatients, loadState: patientsLoadState } = React.useContext(PatientsContext);
    
        const [patientsToUse, setPatientsToUse] = React.useState<Dtos.Patient[]>([]);
    
        const patientsLoadingToUse = patientsLoadState.state === RequestState.None || patientsLoadState.state === RequestState.Pending;
    
        React.useEffect(() => {
            setPatientsToUse((contextPatients as Dtos.Patient[])?.filter(p => !centralLab)) //  || p.preregistrationDate != null
        }, [centralLab, contextPatients, setPatientsToUse]);
    
        React.useEffect(() => {
            setTableCount(x => x + 1);
        }, [patientsToUse, columns]);
    
        return (
            <MemoizedCollapsibleTable
                key={tableCount}
                data={patientsToUse ?? []}
                title={title ?? "Participants"}
                loading={patientsLoadingToUse}
                entityName={entityName ?? "Participant"}
                columns={columns as any}
                onRowClick={onRowClick as any}
                options={tableOptions}
            />
        );
    }

    //const showInitials = React.useMemo(() => {
    //    if (!parsedCountryCode && !institution) {
    //        return false;
    //    }

    //    if (parsedCountryCode) {
    //        return countries?.find(country => country.code === parsedCountryCode)?.name === 'Australia' ?? false;
    //    }

    //    const institutionCountryId = institution?.countryId;

    //    if (!institutionCountryId) {
    //        return false;
    //    }

    //    return countries?.find(country => country.id === institutionCountryId)?.name === 'Australia' ?? false;
    //}, [parsedCountryCode, countries, institution]);

    const showInstitutions = React.useMemo(() => {
        return !parsedInstitutionCode
    }, [parsedInstitutionCode]);

    const [openInfo, setOpenInfo] = React.useState<boolean>(false);

    const onRowClick = React.useCallback((event: React.MouseEvent<Element, MouseEvent>, rowData?: Dtos.IPatient | undefined) => {

        history.push(`/registration/${institutionCode ?? ALL_INSTITUTIONS_CODE}/${rowData?.studyNumber}`)

        //if (rowData?.patientStateId != Dtos.PatientStateType.New) {
        //    history.push(`/registration/${institutionCode ?? ALL_INSTITUTIONS_CODE}/${rowData?.studyNumber}`)
        //}
        //else {
        //    setOpenInfo(true);
        //}

    }, [history, institutionCode]);

    const [reviewDialogOpen, setReviewDialogOpen] = React.useState(false);

    const [consentForm, setConsentForm] = React.useState<Dtos.Consent|undefined>(undefined);

    const [patient, setPatient] = React.useState<Dtos.Patient | undefined>(undefined);

    const onlinePatientManagement = React.useContext(OnlinePatientManagementContext);

    const randomisedPatientsTreatmentAllocationPendingFilter = React.useCallback((patients: Dtos.Patient[]) => patients.filter(p => !p.ukTreatment), []);

    const randomisedPatientsOnStudyFilter = React.useCallback((patients: Dtos.Patient[]) => patients.filter(p => p.ukTreatment && p.treatmentId), []);

    const randomisedPatientsIneligibleFilter = React.useCallback((patients: Dtos.Patient[]) => patients.filter(p => p.ukTreatment && !p.treatmentId), []);

    React.useEffect(() => {
        if (patient) {
            onlinePatientManagement?.serviceStackClient
                .get(new Dtos.ConsentGetSingleByCodes({
                    patientStudyNumber: patient.studyNumber,
                    institutionCode: patient.institutionCode,
                    eventDefinitionCode: 'consent',
                    eventRepeat: 1,
                    formRepeat: 1
                }))
                .then(response => {
                    setConsentForm(response.form);
                })
                .catch((e) => {
                    setConsentForm(undefined);
                })
        } else {
            setConsentForm(undefined);
        }
    }, [patient, setConsentForm, onlinePatientManagement?.serviceStackClient]);

    const showReviewDialog = React.useCallback((show: boolean) => {
        setReviewDialogOpen(show);
    }, [setReviewDialogOpen]);

    const onReviewClick = React.useCallback((patient: Dtos.Patient) => {
        setPatient(patient);
        setReviewDialogOpen(true);
    }, [setPatient, setReviewDialogOpen]);

    const [[canConsentAdminister, canUpdatePatient, canPathologyTestAdminister]] = usePermissionsByIds(permissions, null, null, institution?.id, null, true);

    const randomisedColumns = useRandomisedColumns(institutions, showInstitutions, onReviewClick, canConsentAdminister, canUpdatePatient, canPathologyTestAdminister);
    const randomisedIneligibleColumns = useRandomisedIneligibleColumns(institutions, showInstitutions, onReviewClick, canConsentAdminister, canUpdatePatient, canPathologyTestAdminister);

    const awaitingRandomisationColumns = useAwaitingRandomisationPatientColumns(institutions, showInstitutions, onReviewClick, canConsentAdminister, canUpdatePatient, canPathologyTestAdminister);


    return (
        <>
            <InstitutionBreadcrumbs />
            <div
                className={classes.container}
            >
                <TrialContextSelector
                    onChange={onSelect}
                    allowAllMasterGroups={true}
                    allowAllCollaboratingGroups={true}
                    allowAllCountries={true}
                    allowAllInstitutions={true}
                    hideMasterGroups={!showAdminPage}
                    hideCollaboratingGroups={!showAdminPage}
                    hideCountries={!showAdminPage}
                    masterGroupCode={masterGroupCode}
                    collaboratingGroupCode={collaboratingGroupCode}
                    countryCode={countryCode}
                    institutionCode={institutionCode}
                >
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.addPatientColumn}
                    >
                        <ProCompletionGuidelines />
                    </Grid>
                </TrialContextSelector>

                <Grid
                    item
                    xs={12}
                    sm={6}
                    className={classes.search}
                >
                    <Search />
                </Grid>
                

                {
                    institution &&
                        institution.recruitmentDisabled ?
                        <Alert
                            className={classes.recruitmentDisabled}
                            variant="filled"
                            severity="info"
                        >
                            Site Disabled
                        </Alert> :
                        null
                }
                <PatientsByCodesAndFilterResolver
                    masterGroupCode={parsedMasterGroupCode}
                    collaboratingGroupCode={parsedCollaboratingGroupCode}
                    countryCode={parsedCountryCode}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={awaitingRandomisationStates}
                >
                    {/* <PatientCollapsibleTable
                        title="Patients Awaiting Randomisation"
                        entityName="Patient"
                        columns={awaitingRandomisationColumns}
                        onRowClick={onRowClick as any}
                        pluralizeTitle={false}
                        disabledTableText={'There are no patients awaiting randomisation'}
                    /> */}
                    
                    <PatientTable
                        title="Patients Awaiting Randomisation"
                        entityName="Patient"
                        onRowClick={onRowClick}
                        columns={awaitingRandomisationColumns}
                        centralLab={canPathologyTestAdminister && !canConsentAdminister}
                    />

                    <ConsentReviewDialogWrapper
                        open={reviewDialogOpen}
                        patient={patient}
                        consentForm={consentForm}
                        onCancel={() => { showReviewDialog(false); setPatient(undefined); }}
                    />
                </PatientsByCodesAndFilterResolver>
                <PatientsByCodesAndFilterResolver
                    masterGroupCode={parsedMasterGroupCode}
                    collaboratingGroupCode={parsedCollaboratingGroupCode}
                    countryCode={parsedCountryCode}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={randomisedStates}
                    filterFx={randomisedPatientsTreatmentAllocationPendingFilter}
                >
                    {/* <PatientCollapsibleTable
                        title="Randomised - treatment allocation pending"
                        entityName="Patient"
                        columns={randomisedColumns}
                        onRowClick={onRowClick as any}
                        pluralizeTitle={false}
                        disabledTableText={'There are no randomised patients pending treatment allocation'}
                    /> */}
                    
                    <PatientTable
                        title="Randomised - treatment allocation pending"
                        entityName="Patient"
                        onRowClick={onRowClick}
                        columns={randomisedColumns}
                        centralLab={canPathologyTestAdminister && !canConsentAdminister}
                    />


                    <ConsentReviewDialogWrapper
                        open={reviewDialogOpen}
                        patient={patient}
                        consentForm={consentForm}
                        onCancel={() => { showReviewDialog(false); setPatient(undefined); }}
                    />
                </PatientsByCodesAndFilterResolver>
                <PatientsByCodesAndFilterResolver
                    masterGroupCode={parsedMasterGroupCode}
                    collaboratingGroupCode={parsedCollaboratingGroupCode}
                    countryCode={parsedCountryCode}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={randomisedStates}
                    filterFx={randomisedPatientsOnStudyFilter}
                >
                    {/* <PatientCollapsibleTable
                        title="Randomised - on-study"
                        entityName="Patient"
                        columns={randomisedColumns}
                        onRowClick={onRowClick as any}
                        pluralizeTitle={false}
                        disabledTableText={'There are no randomised patients on study'}
                    /> */}
                    
                    <PatientTable
                        title="Randomised - on-study"
                        entityName="Patient"
                        onRowClick={onRowClick}
                        columns={randomisedColumns}
                        centralLab={canPathologyTestAdminister && !canConsentAdminister}
                    />


                    <ConsentReviewDialogWrapper
                        open={reviewDialogOpen}
                        patient={patient}
                        consentForm={consentForm}
                        onCancel={() => { showReviewDialog(false); setPatient(undefined); }}
                    />
                </PatientsByCodesAndFilterResolver>
                <PatientsByCodesAndFilterResolver
                    masterGroupCode={parsedMasterGroupCode}
                    collaboratingGroupCode={parsedCollaboratingGroupCode}
                    countryCode={parsedCountryCode}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={randomisedStates}
                    filterFx={randomisedPatientsIneligibleFilter}
                >
                    {/* <PatientCollapsibleTable
                        title="Randomised - treatment outside protocol"
                        entityName="Patient"
                        columns={randomisedIneligibleColumns}
                        onRowClick={onRowClick as any}
                        pluralizeTitle={false}
                        disabledTableText={'There are no randomised - treatment outside protocol patients'}
                    /> */}
                    
                    <PatientTable
                        title="Randomised - treatment outside protocol"
                        entityName="Patient"
                        onRowClick={onRowClick}
                        columns={randomisedIneligibleColumns}
                        centralLab={canPathologyTestAdminister && !canConsentAdminister}
                    />

                    <ConsentReviewDialogWrapper
                        open={reviewDialogOpen}
                        patient={patient}
                        consentForm={consentForm}
                        onCancel={() => { showReviewDialog(false); setPatient(undefined); }}
                    />
                </PatientsByCodesAndFilterResolver>
                <PatientsByCodesAndFilterResolver
                    masterGroupCode={parsedMasterGroupCode}
                    collaboratingGroupCode={parsedCollaboratingGroupCode}
                    countryCode={parsedCountryCode}
                    institutionCode={parsedInstitutionCode}
                    patientStateIds={ineligibleStates}
                >
                    {/* <PatientCollapsibleTable
                        title="Ineligible"
                        entityName="Patient"
                        columns={randomisedIneligibleColumns}
                        onRowClick={onRowClick as any}
                        pluralizeTitle={false}
                        disabledTableText={'There are no ineligible patients'}
                    /> */}
                    
                    <PatientTable
                        title="Ineligible"
                        entityName="Patient"
                        onRowClick={onRowClick}
                        columns={randomisedIneligibleColumns}
                        centralLab={canPathologyTestAdminister && !canConsentAdminister}
                    />

                </PatientsByCodesAndFilterResolver>
            </div>
            <Dialog
                open={openInfo}
                onClose={() => setOpenInfo(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">PROs Unavailable</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Patient must be randomised in order to begin PROs
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenInfo(false)} color="secondary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
            
        </>
    );
}


/*
 * ----------------------------------------------------------------------------------
 * Default Export
 * ----------------------------------------------------------------------------------
 */

export default Registration;
